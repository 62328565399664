<template>
    <div class="row m-0">
        <div class="col-12 justify-content-center">
            <div class="row headers text-dark text-center all-box-shadows py-3">
                <h6 class="m-0 mx-auto">
                    {{ $t("made") }}
                    <svg class="footer-heart" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z" stroke="#22333B" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    {{ $t("by") }}
                    <a href="Abolfazl Mohajeri"> {{ $t("abolfazl") }} {{ $t("mohajeri") }} </a>
                </h6>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {

            }
        },
        mounted() {

        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
