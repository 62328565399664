import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "./i18n/i18n";
import Master from "./views/Master";
import Index from "./views/pages/Index";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '',
            redirect: '/en'
        }, {
            path: '/:lang',
            component: Master,
            children: [
                {
                    path: '',
                    name: 'Index',
                    component: Index,
                }
            ]
        }
    ]
});

let loading = null;
router.beforeResolve((to, from, next) => {
    if (to.name) {
        loading = router.app.$vs.loading({
            type: 'corners',
            background: '#cdd3e1',
            color: '#22333B'
        });
    }
    next();
});

router.beforeEach((to, from, next) => {
    const lang = to.params.lang;
    if ( i18n.locale !== lang ) {
        i18n.locale = lang;
    }
    if(!["en", "fa"].includes(lang)) {
        i18n.locale = "en";
        next("/en");
    }
    next();
});

router.afterEach(() => {
    loading.close();
});

export default router;