<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
                <h4 class="m-0 mx-auto"> {{ $t("contact_me") }} </h4>
            </div>
            <div class="row sections m-0">
                <div class="col-12 my-2">
                    <p> {{ $t("contactText") }} </p>
                    <div id="contact-detail" class="ltr text-left">
                        <p> <span class="mark"> <i class='bx bxs-map'></i> </span> {{ $t("contactPlace") }} </p>
                        <p> <span class="mark"> <i class='bx bx-at'></i> </span> abmohajeri1@gmail.com </p>
                        <p> <span class="mark"> <i class='bx bx-phone'></i> </span> {{ $t("mobile") }} </p>
                        <p> <span class="mark"> <i class='bx bx-cool'></i> </span> abmohajeri </p>
                    </div>
                    <p v-html="$t('contactLink')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "Contact",
        data() {
            return {
                language: this.$i18n.locale,
                active: 0
            }
        },
        mounted() {
            let outer = this;
            $("#submitContact").on("click", function (e) {
                e.preventDefault();
                $("#contact-button-loading").removeClass("d-none");
                let email = $("#email").val();
                let text = $("#text").val();
                let captcha = $("#captchaTextBox").val();
                $.ajax({
                    url: '/contact.php',
                    method: "post",
                    data: {
                        email: email,
                        text: text,
                        captcha: captcha
                    },
                    success: function () {
                        outer.openNotification('bottom-center', 'success', 'Done, Your Message Sent');
                        $("#contact-button-loading").addClass("d-none");
                    },
                    error: function () {
                        outer.openNotification('bottom-center', 'danger', 'An Error Occurred, Please Try Again');
                        $("#contact-button-loading").addClass("d-none");
                    }
                })
            });
        },
        watch: {
            '$i18n.locale': function (newVal) {
                let add = newVal === 'fa' ? 'rtl' : 'ltr';
                let remove = newVal === 'fa' ? 'ltr' : 'rtl';
                $("#contact-detail").removeClass(remove).addClass(add);
            }
        },
        methods : {
            openNotification(position = null, color, text) {
                this.$vs.notification({
                    progress: 'auto',
                    color,
                    position,
                    title: text,
                    text: ``
                })
            }
        }
    }
</script>

<style>

</style>

<style scoped>

</style>
